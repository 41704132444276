import { styled, Box } from '@mui/material'
import StrapiLink from '../Link'

export const HeadingStyles = styled('h1')(({ theme }) => ({
  color: '#0a0a0a',
  display: 'flex',
  fontSize: '40px',
  fontWeight: '600',
  justifyContent: 'center',
  lineHeight: '56px',
  margin: '28px auto 0',
  maxWidth: '928px',
  textAlign: 'center',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '27px',
    lineHeight: '38px',
    width: '100%',
  },
}))

export const SubheadingStyles = styled('p')(({ theme }) => ({
  fontSize: '25px',
  fontWeight: '500',
  lineHeight: '34px',
  margin: '0 auto',
  maxWidth: '928px',
  textAlign: 'center',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '28px',
    padding: '0 16px',
    width: '100%',
  },
}))

export const LinkStyles = styled(StrapiLink)(({ theme }) => ({
  display: 'block',
  fontSize: '20px',
  margin: 'auto',
  textAlign: 'center',
  width: 'fit-content !important',
  '& div': {
    marginTop: '16px',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      marginTop: '22px',
      width: 'fit-content !important',
    },
  },
  '&:hover': {
    textDecoration: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    width: '100%',
  },
}))

export const ParagraphWrapper = styled('div')(({ theme }) => ({
  marginTop: '48px',
  paddingBottom: '16px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '28px',
    paddingBottom: '8px',
  },
}))

export const ParagraphMainHeadingStyles = styled('h2')(({ theme }) => ({
  color: '#07263B',
  fontSize: '30px',
  fontWeight: '600',
  lineHeight: '42px',
  textAlign: 'center',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '23px',
    lineHeight: '32px',
    textAlign: 'left',
    width: '100%',
  },
}))

export const ParagraphImageGalleryHeadingStyles = styled('h2')(({ theme }) => ({
  color: '#07263B',
  fontSize: '30px',
  fontWeight: '600',
  lineHeight: '42px',
  marginBottom: '10px',
  textAlign: 'center',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '23px',
    lineHeight: '32px',
    marginBottom: '8px',
    textAlign: 'left',
    width: '100%',
  },
}))

export const ParagraphBodyStyles = styled('div')(({ theme }) => ({
  '& p': {
    color: '#151515',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '24px',
    margin: '8px auto 0',
    maxWidth: '700px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const SliderWrapperStyles = styled('div')(({ theme }) => ({
  '& .swiper-slide div div div:nth-child(3) div:nth-child(2)': {
    color: '#151515',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '24px',
    textAlign: 'left',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '50px',
  },
}))

export const BlogImageGalleryStyles = styled('div')(({ theme }) => ({
  marginBottom: '48px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
  },
}))
