import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { styled, Box } from '@mui/material'

import BlogSubsection from './BlogSubsection'
import StrapiMarkdown from '../Markdown'

const ParagraphWrapperStyles = styled('div')(({ theme, BackgroundColorHex }) => ({
  backgroundColor: BackgroundColorHex && BackgroundColorHex,
  padding: '60px 0',
  marginBottom: '40px',
  [theme.breakpoints.down('sm')]: {
    padding: '32px 10px',
    margin: '0 -10px 40px',
  },
}))

const ParagraphHeadingStyles = styled('h2')(({ theme }) => ({
  color: '#07263B',
  fontSize: '30px',
  fontWeight: '600',
  lineHeight: '42px',
  textAlign: 'center',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '23px',
    lineHeight: '32px',
    textAlign: 'left',
    width: '100%',
  },
}))

const ParagraphBodyStyles = styled('div')(({ theme }) => ({
  '& p': {
    color: '#151515',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '24px',
    margin: '8px auto 0',
    maxWidth: '700px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const BlogSection = ({ data }) => {
  const { testId, Heading, Body, BackgroundColorHex, BlogSubsections } = data?.BlogSection || {}
  const markdown = Body?.Markdown
  const headingContent = Heading || markdown
  return (
    <Box data-testid={testId} sx={{ marginBottom: { xs: '40px', sm: '60px' } }}>
      {headingContent && (
        <ParagraphWrapperStyles BackgroundColorHex={BackgroundColorHex}>
          <ParagraphHeadingStyles>{Heading}</ParagraphHeadingStyles>
          <ParagraphBodyStyles>
            <StrapiMarkdown gridWrapper={false} data={markdown} />
          </ParagraphBodyStyles>
        </ParagraphWrapperStyles>
      )}
      {BlogSubsections.map((blogSubsection, item) => (
        <BlogSubsection key={blogSubsection?.testId} data={blogSubsection} odd={item % 2 === 1} />
      ))}
    </Box>
  )
}

export default BlogSection

BlogSection.propTypes = {
  data: PropTypes.object,
}

export const BlogSectionFragment = graphql`
  fragment BlogSectionFragment on BaseBlogSection {
    BlogSection {
      testId
      Heading
      Body {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      BackgroundColorHex
      BlogSubsections {
        ...BlogSubsectionFragment
      }
    }
  }
`
