import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { styled, Link as MuiLink, Box, useMediaQuery, useTheme } from '@mui/material'
import StrapiLink from './Link'

// consider other scrollTo strategies
// may need to modify scroll when going up to element vs going down
// https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
const Wrapper = styled('div')(({ theme, showMeganavMobile, hideInfoBanner }) => ({
  display: 'flex',
  padding: '15px 7px',
  borderRadius: '7px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  gap: '10px',
  position: 'sticky',
  zIndex: 101,
  top: `${116 - (showMeganavMobile ? 0 : 46)}px`,
  background: 'rgba(255, 255, 255, 0.60)',
  backdropFilter: 'blur(5px)',
  transition: '.2s all ease-in-out',
  whiteSpace: 'nowrap',
  overflow: 'auto',
  '&::before, &::after': {
    content: '""',
    margin: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    top: `${132 - (showMeganavMobile ? 0 : 64)}px`,
    gap: '15px',
    padding: '15px',
    marginTop: '100px',
  },
  [theme.breakpoints.up('md')]: {
    width: 'fit-content !important',
    padding: '25px 10px',
    maxWidth: '970px',
    margin: '0 auto',
    top: `${160 - (hideInfoBanner ? 40 : 0)}px`,
    gap: '30px',
  },
  '& .anchor-nav-link': {
    color: theme.palette.primary.main,
    fontWeight: 'bold !important',
    fontSize: '14px',
    lineHeight: '14px',
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    background: 'transparent',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '16px',
    },
    '&, &:hover': {
      textDecoration: 'none !important',
    },
    '&.divider': {
      color: '#003566',
    },
  },
}))

const StrapiAnchorNavigation = ({ data }) => {
  const [headerHeight, setHeaderHeight] = useState()
  const [header, setHeader] = useState()
  const { showMeganavMobile, hideInfoBanner } = useSelector(state => state.global)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { Items } = data?.AnchorNavigation || {}

  const onResize = useCallback(() => {
    const resizeHeader = isMobile
      ? typeof document !== 'undefined' && document.querySelectorAll('header.mobile-only')?.[0]
      : typeof document !== 'undefined' && document.querySelectorAll('header.desktop-only')?.[0]
    setHeader(resizeHeader)
  }, [isMobile])

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', onResize)
    }
    setTimeout(() => {
      onResize()
    }, [500])
    return () => {
      window.removeEventListener('resize', onResize)
      // clearTimeout()
    }
  }, [onResize])

  useLayoutEffect(() => {
    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver(() => {
      const headerSize = header?.offsetHeight
      setHeaderHeight(headerSize)
    })
    if (header) resizeObserver.observe(header)
    return () => resizeObserver?.disconnect()
  }, [header])

  const handleAnchorClick = (e, anchorNavId) => {
    e.preventDefault()
    const scrollToElement = document?.getElementById(anchorNavId)?.getBoundingClientRect()?.top + window.scrollY
    const scrollToPosition = scrollToElement - headerHeight
    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth',
    })
  }

  if (!Items) return null

  const renderItem = item => {
    if (!item) return null
    if (item.Link) return <StrapiLink className="anchor-nav-link" data={item.Link} key={item.Link.id} />
    return (
      <>
        <MuiLink
          key={`${item.id}-desktop`}
          href="#"
          onClick={e => handleAnchorClick(e, item.AnchorNavID)}
          sx={{ display: { xs: 'none', md: 'block' } }}
          className="anchor-nav-link"
        >
          {item.DisplayName}
        </MuiLink>
        <MuiLink
          key={`${item.id}-mobile`}
          href="#"
          onClick={e => handleAnchorClick(e, item.AnchorNavIDMobile ?? item.AnchorNavID)}
          sx={{ display: { xs: 'block', md: 'none' } }}
          className="anchor-nav-link"
        >
          {item.DisplayNameMobile ?? item.DisplayName}
        </MuiLink>
      </>
    )
  }

  return (
    <Wrapper showMeganavMobile={showMeganavMobile} hideInfoBanner={hideInfoBanner}>
      {Items.map((item, idx) => (
        <>
          {idx > 0 && <Box className="divider anchor-nav-link">|</Box>}
          {renderItem(item)}
        </>
      ))}
    </Wrapper>
  )
}

StrapiAnchorNavigation.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrapiAnchorNavigation

export const AnchorNavigationFragment = graphql`
  fragment PageAnchorNavigationFragment on StrapiAnchorNavigation {
    id
    testId
    Items {
      ... on BaseAnchorNav {
        id
        AnchorNavID
        DisplayName
        testId
        DisplayNameMobile
        AnchorNavIDMobile
      }
      ... on BaseLink {
        Link {
          ...StrapiLinkFragment
        }
      }
    }
  }
`
