import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import ImageWithSku from '../ImageWithSku'
import StrapiSliderV2 from '../Slider-v2'
import BlogSection from './BlogSection'
import StrapiImageGallery from '../ImageGallery/ImageGallery'
import StrapiMarkdown from '../Markdown'

import {
  HeadingStyles,
  SubheadingStyles,
  LinkStyles,
  ParagraphWrapper,
  ParagraphMainHeadingStyles,
  ParagraphImageGalleryHeadingStyles,
  ParagraphBodyStyles,
  BlogImageGalleryStyles,
  SliderWrapperStyles,
} from './index.styles'

const Blog = ({ data }) => {
  const {
    testId,
    MainImage,
    Heading,
    SubHeading,
    Link,
    ParagraphHeading,
    ParagraphBody,
    Slider,
    BlogSections,
    ImageGallery,
    ImageGalleryHeading,
  } = data?.Blog || {}
  const markdown = ParagraphBody?.Markdown
  return (
    <div data-testid={testId}>
      {MainImage && <ImageWithSku data={MainImage} />}
      {Heading && <HeadingStyles align="center">{Heading}</HeadingStyles>}
      {SubHeading && <SubheadingStyles align="center">{SubHeading}</SubheadingStyles>}
      {Link && <LinkStyles data={{ ...Link }} />}
      {(ParagraphHeading || ParagraphBody) && (
        <ParagraphWrapper>
          <ParagraphMainHeadingStyles>{ParagraphHeading}</ParagraphMainHeadingStyles>
          <ParagraphBodyStyles>
            <StrapiMarkdown gridWrapper={false} data={markdown} />
          </ParagraphBodyStyles>
        </ParagraphWrapper>
      )}
      {Slider && (
        <SliderWrapperStyles>
          <StrapiSliderV2 data={Slider} blogCollection />
        </SliderWrapperStyles>
      )}
      {BlogSections && BlogSections?.map(blogSection => <BlogSection key={blogSection?.testId} data={blogSection} />)}
      {ImageGallery && (
        <BlogImageGalleryStyles>
          {ImageGalleryHeading && (
            <ParagraphImageGalleryHeadingStyles>{ImageGalleryHeading}</ParagraphImageGalleryHeadingStyles>
          )}
          <StrapiImageGallery data={ImageGallery} />
        </BlogImageGalleryStyles>
      )}
    </div>
  )
}

export default Blog

Blog.propTypes = {
  data: PropTypes.object,
}

export const BlogFragment = graphql`
  fragment StrapiPageBlogFragment on StrapiBlog {
    testId
    MainImage {
      ...BaseImageWithSkuFragment
    }
    Heading
    SubHeading
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    ParagraphHeading
    ParagraphBody {
      Markdown {
        ...StrapiPageMarkdownFragment
      }
    }
    Slider {
      Slider {
        ...StrapiSliderfragment
      }
    }
    BlogSections {
      ...BlogSectionFragment
    }
    ImageGallery {
      ImageGallery {
        ...ImageGalleryFragment
      }
    }
    ImageGalleryHeading
  }
`
